import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CreateCollectionModal from "./CreateCollectionModal";
import { collectionlistAction } from "../Redux/Action/CollectionAction";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../Components/common/SearchBar";
import LoaderComponent from "../Components/common/LoaderComponent";
import { toast } from "react-hot-toast";
import { browseAddToCollectionAction } from "../Redux/Action/BrowseAction";
import {
  studentDetailAction,
  studentShareCollectionAction,
} from "../Redux/Action/StudentAction";
import { AddCollectionToclassAction, classdetailsAction } from "../Redux/Action/ClassAction";

const SaveToCollection = ({ show, handleClose, selectedSave, type ,shortContainerRef }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCollection, setShowCollection] = useState(false);

  const handleCloseCollection = () => {
    setShowCollection(false);
  };

  const handleShowCollection = () => setShowCollection(true);
  const FilterPayload = {
    searchValue: searchValue,
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(collectionlistAction(FilterPayload)).finally(() => {
      setIsLoading(false);
    });
  }, [searchValue, showCollection]);

  const collectionListData = useSelector(
    (state) => state?.collectionSlice?.collectionData
  );

  const handleSave = async (ele) => {
    setLoading(true);
    const payload = {
      id: selectedSave,
      collectionId: selectedItems,
    };
    try {
      const res = await dispatch(browseAddToCollectionAction(payload));
      if (res?.payload?.statusCode == 200) {
        toast.success(res?.payload?.message, {
          iconTheme: {
            primary: '#4318ff',
            secondary: '#FFFAEE',
          },
        });
        dispatch(collectionlistAction(FilterPayload))
        setLoading(false);
        handleClose();
        setSelectedItems([]);
      } else {
        if (Array.isArray(selectedItems) && selectedItems?.length === 0) {
          toast.error("Collection is required");
          setLoading(false);
        } else {
          toast.error(res?.payload?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleShare = async (ele) => {
    setLoading(true);
    const payload = {
      studentId: selectedSave,
      collection: selectedItems,
    };
    try {
      const res = await dispatch(studentShareCollectionAction(payload));
      if (res?.payload?.statusCode == 200) {
        toast.success(res?.payload?.message, {
          iconTheme: {
            primary: '#4318ff',
            secondary: '#FFFAEE',
          },
        });
        setLoading(false);
        dispatch(studentDetailAction(selectedSave));
        handleClose();
        setSelectedItems([]);
      } else {
        if (Array.isArray(selectedItems) && selectedItems?.length === 0) {
          toast.error("Collection is required");
          setLoading(false);
        } else {
          toast.error(res?.payload?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };



  const handleShareCollectionWithClass = async (ele) => {
    setLoading(true);
    const payload = {
      class: selectedSave,
      collection: selectedItems,

    };
    try {
      const res = await dispatch(AddCollectionToclassAction(payload));
      if (res?.payload?.statusCode == 200) {
        toast.success(res?.payload?.message, {
          iconTheme: {
            primary: '#4318ff',
            secondary: '#FFFAEE',
          },
        });
        setLoading(false);
        dispatch(classdetailsAction(selectedSave))
        handleClose();
        setSelectedItems([]);
      } else {
        if (Array.isArray(selectedItems) && selectedItems?.length === 0) {
          toast.error("Collection is required");
          setLoading(false);
        } else {
          toast.error(res?.payload?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };







  const handleChoose = (item) => {
    if (selectedItems.includes(item._id)) {
      setSelectedItems(
        selectedItems.filter((selectedKey) => selectedKey !== item._id)
      );
    } else {
      setSelectedItems([...selectedItems, item._id]);
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="comn-modal-set"
        container={shortContainerRef?.current}
      >
        <Modal.Header className="d-block" style={{ border: "0" }}>
          <Row>
            <Col lg={12} md={12} className="d-flex justify-content-center mb-4">
              <div>






                {type === "StudentDetail" && <Modal.Title>Share Collection</Modal.Title>}
                {type === "StudentDetail" && <p className="text-center mb-0">Share collection with student </p>}

                {type === "PlayBack" && <Modal.Title>Save Video to Collection</Modal.Title>}
                {type === "PlayBack" && <p className="text-center mb-0">Save this video to a collection </p>}

                {type === "CollectionAddToTheClass" && <Modal.Title>Add Collection</Modal.Title>}
                {type === "CollectionAddToTheClass" && <p className="text-center mb-0">Add collection with the class </p>}
              </div>
            </Col>
          </Row>
          <div className="modal-right">
            <button className="cmn-yellow" onClick={handleShowCollection}>
              Create New Collection
            </button>
            <button className="menu-dots" onClick={handleClose}>
              <img src={require("../Assets/Images/dotsweb.svg").default} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="user-pro-search">
                  <SearchBar setSearchValue={setSearchValue} />
                </div>
              </Col>
            </Row>
            <Row>
              {isLoading ? (
                <LoaderComponent />
              ) : collectionListData &&
                collectionListData?.collection &&
                collectionListData?.collection.length > 0 ? (
                <>
                  {collectionListData.collection.map((ele, index) => {
                    return (
                      <Col lg={6} className="mt-4" key={index}>
                        <div
                          onClick={() => handleChoose(ele)}
                          className={
                            selectedItems.includes(ele._id)
                              ? "collection-video-inner active-box "
                              : "collection-video-inner"
                          }
                        >
                          <Row style={{ cursor: "pointer" }}>
                            <Col lg={3} md={3} sm={3}>
                              <img
                                src={
                                  ele?.image
                                    ? ele?.image
                                    : require("../Assets/Images/C.png")
                                }
                              />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              className="d-flex align-items-start"
                            >
                              <h3>{ele.name}</h3>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                              <p className="text-end">
                                {Array.isArray(ele?.video) &&
                                  ele?.video.length > 0
                                  ? ele?.video?.length
                                  : 0}{" "}
                                videos
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <div className="data-not-found-cmn ad-height-pop">
                  <p>No Data Found</p>
                </div>
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center pb-4">
          {/* {type === "StudentDetail" ? (
            <Button
              variant="primary"
              className="modal-f-btn"
              onClick={handleShare}
              disabled={loading ? true : false}
            >
              Share
            </Button>
          ) : (
            <Button
              variant="primary"
              className="modal-f-btn"
              onClick={handleSave}
              disabled={loading ? true : false}
            >
              {loading ? "loading..." : "Save"}
            </Button>
          )} */}


          {type === "StudentDetail" &&
            <Button
              variant="primary"
              className="modal-f-btn"
              onClick={handleShare}
              disabled={loading ? true : false}
            >

              {loading ? "loading..." : "Share"}
            </Button>}


          {type === "PlayBack" &&
            <Button
              variant="primary"
              className="modal-f-btn save-button"
              onClick={handleSave}
              disabled={loading ? true : false}
            >
              {loading ? "loading..." : "Save"}
            </Button>}


          {type === "CollectionAddToTheClass" &&
            <Button
              variant="primary"
              className="modal-f-btn"
              onClick={handleShareCollectionWithClass}
              disabled={loading ? true : false}
            >
              {loading ? "loading..." : "Add"}
            </Button>}


        </Modal.Footer>
      </Modal>

      <CreateCollectionModal
        showCollection={showCollection}
        handleCloseCollection={handleCloseCollection}
        value={value}
        setValue={setValue}
        type="CreateCollection"
        shortContainerRef={shortContainerRef}
      />
    </>
  );
};

export default SaveToCollection;
