import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchBar from './common/SearchBar';
import {useSelector } from 'react-redux';
import UserProfile from './common/UserProfile';
import { getQueryParams } from '../Util/HelperFunction';
import BrowseSearch from './common/BrowseSearch';
import PlaybackSearch from './common/PlaybackSearch';
const TopHeader = ({ setSearchValue,searchValue, handleSelectClass, groupData, type, handleFocusActionState }) => {
const Userstore = useSelector((state) => state?.authSlice.userData?.payload)
  const Location = useLocation()
  const queryParams = getQueryParams(Location?.search);
  const { selectedGroup ,categories} = queryParams;
  return (
    <>

      {type === "DetailPages" ?

        <>
      <Row className="mt-2">
          <Col lg={10}></Col>
          <Col lg={2} className="d-flex align-items-center justify-content-end">
            <div className="d-flex top-bar">
        
              {/* <Link to="">
              { Userstore && Userstore?.image ?
              <img style={{ borderRadius: "50%" }} className='dynamic-image-cover' src={Userstore.image} alt="user" />
                :
              <img src={require('../Assets/Images/c.png')} alt="user" />
              }

              </Link> */}
            </div>
          </Col>
        </Row>
        </>

        :
        <Container fluid>
          <div className="top-fix-sec">
            <Row className="mt-2">
              <Col lg={3} md={0} sm={0}></Col>
              <Col lg={5} md={5} sm={5}>
                {type === "Browse" && <BrowseSearch setSearchValue={setSearchValue} searchValue={searchValue} />}
                {type === "PlayBack" && <PlaybackSearch setSearchValue={setSearchValue} handleFocusActionState={handleFocusActionState}/>}
              </Col>
              <Col lg={2} md={4}>
                {type === "Browse" &&
                  <Form.Select
                    aria-label="Default select example"
                    className="cmn-select"
                    onChange={handleSelectClass}
                    value={selectedGroup}
                  >
                    <option value="">All Year Groups</option>
                    {groupData.map((ele, index) => (
                      <option key={index} value={ele.id}>
                        {ele.name}
                      </option>
                    ))}
                  </Form.Select>}
              </Col>
              <Col
                lg={2}
                md={2}
                sm={4}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="d-flex top-bar">
                 {/* <UserProfile image={Userstore.image}/> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>}

    </>
  );
};

export default TopHeader;







