import React, { useState } from 'react'
import LikeButton from './common/LikeButton'
import SaveButton from './common/SaveButton'
import DownloadCover from './common/DownloadCover'
import ReportModal from '../modals/ReportModal'
import { FaRegClosedCaptioning } from "react-icons/fa6";
import { CiFlag1 } from "react-icons/ci";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import ShareVideo from './common/ShareVideo'
import toast, { Toaster } from 'react-hot-toast'
// import ShareVideo from './common/ShareVideo'

const SideActions = ({ data, handleShow, isSubtitle, setIsSubtitle, shortContainerRef, fullscreenState }) => {
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [showPopover, setShowPopover] = useState(false);


  const ToggleClick = () => {
    if (!isSubtitle) {
      toast('Subtitles/CC turned ON',
        {
          icon: '',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setIsSubtitle(!isSubtitle);
      setShowPopover(false);
    } else {
      toast('Subtitles/CC turned off',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setIsSubtitle(!isSubtitle);
      setShowPopover(false);
    }


  }

  console.log("isSubtitle:", isSubtitle);
  const handleReportModalOpen = (ele) => {
    setReportModalOpen(true);
    setShowPopover(false);
    setReportId(ele._id);
  };


  const handleReportModalClose = () => {
    setReportModalOpen(false);
    setReportId(null);
  };


  const popoverCompo = (ele) => {
    return (
      <Popover
        id="popover-positioned-left"
        className="help-popup"
        title="Popover left"
        ref={shortContainerRef?.current}
      >
        <Col className="">
          <div className='remove-div'>
            <div className='caption-sub'>
              <span> <FaRegClosedCaptioning size={20} /> </span>
              {isSubtitle ?
                <p onClick={ToggleClick}>CC - ON</p> :
                <p onClick={ToggleClick}>CC - OFF</p>
              }
            </div>
            <div className='flag-icon'>
              <span><CiFlag1 size={20} /> </span>
              <p onClick={() => handleReportModalOpen(ele)}>
                Report
              </p>
            </div>
          </div>
        </Col>
      </Popover>
    );
  };




  const handleToggleOpen = () => {
    setShowPopover(!showPopover);
  };


  return (
    <div>
      <div className=" reaction caption-text  video-side-btns  ms-4">
      <ShareVideo
          data={data}
          shortContainerRef={shortContainerRef}
          fullscreenState={fullscreenState}
        />

        <LikeButton
          initialLike={data}
        />
        <SaveButton
          handleShow={handleShow}
          isSaved={data}
        />

        <DownloadCover
          data={data}
          shortContainerRef={shortContainerRef}
        />
  

        <OverlayTrigger
          show={showPopover}
          container={shortContainerRef?.current}
          trigger="click"
          placement="bottom"
          overlay={popoverCompo(
            data
          )}
          onToggle={
            handleToggleOpen
          }
          rootClose
        >
          <div className="btns-main">
            <button className='view-reels-side-btn'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12ZM13.25 19.5C13.25 20.1904 12.6904 20.75 12 20.75C11.3096 20.75 10.75 20.1904 10.75 19.5C10.75 18.8096 11.3096 18.25 12 18.25C12.6904 18.25 13.25 18.8096 13.25 19.5ZM12 3.5C9.37665 3.5 7.25 5.62665 7.25 8.25H9.25C9.25 6.73122 10.4812 5.5 12 5.5H12.1179C13.5716 5.5 14.75 6.67844 14.75 8.13212V8.77358C14.75 9.52345 14.2702 10.1892 13.5588 10.4263C12.0307 10.9357 11 12.3657 11 13.9764V17H13V13.9764C13 13.2266 13.4798 12.5608 14.1912 12.3237C15.7193 11.8143 16.75 10.3843 16.75 8.77358V8.13212C16.75 5.57387 14.6761 3.5 12.1179 3.5H12Z" fill="#50C45D" fill-opacity="0.85" />
              </svg>
            </button>
            <p>Help</p>
          </div>
        </OverlayTrigger>
      </div>
      <ReportModal
        shortContainerRef={shortContainerRef}
        reportModalOpen={reportModalOpen}
        handleReportModalClose={handleReportModalClose}
        isBrowse={true}
        reportId={reportId}
      />

    </div>
  )
}

export default SideActions;
