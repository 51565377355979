import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const PlaybackSearch = ({ setSearchValue, hide, handleFocusActionState }) => {
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        if (value === "") {
            setSearchValue(""); // Update the search value in state when input is cleared
        }
    };

    const handleSearch = () => {
        console.log("Searching for:", searchText);
        setSearchValue(searchText);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission
            handleSearch();
        }
    };

    return (
        <div className={`user-pro-search ${hide && "inner-search-bar"} d-flex`}>
    <div className="playback-container">
            <Form.Control
                type="search"
                placeholder="Search"
                className="form-control w-100"
                value={searchText}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress}
                onFocus={() => handleFocusActionState(true)}
                onBlur={() => handleFocusActionState(false)}
            />
                        <button className="search-button" onClick={handleSearch}>Search</button>  
                </div>
        </div>
    );
};

export default PlaybackSearch;


