import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Form as BootstrapForm, FormControl } from "react-bootstrap";
import DropzoneArea from "../Components/DropzoneArea";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  collectionEditAction,
  collectioncreateAction,
  collectiondetailsAction,
  collectionlistAction,
} from "../Redux/Action/CollectionAction";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import CollectionImagesModal from "./CollectionImagesModal";

const CreateCollectionModal = ({
  showCollection,
  handleCloseCollection,
  setValue,
  value,
  handleDeleteOpen,
  editData,
  type,
  shortContainerRef
}) => {
  const validationSchema = Yup.object({
    name: Yup.string()
    .required("This field is required")
    .min(5, "Title must be at least 5 characters")
    .max(50, "Title cannot be longer than 50 characters"),
    // image: Yup.mixed().required("Image is required"),
  });
  const dispatch = useDispatch();

  const [Modalshow, setModalshow] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSlectedImage] = useState("")
  const intialData = { name: "", image: "" };


  const handleCloseColImgModal = () => {
    setModalshow(false)
   
  }

  const handleOpenColImgModal = () => {
    setModalshow(true)
  }



  return (
    <>
      <Modal
        size="lg"
        show={showCollection}
        onHide={handleCloseCollection}
        className="comn-modal-set"
       container={shortContainerRef?.current}
      >
        <Modal.Header className="d-block" style={{ border: "0" }}>
          <Row>
            <Col lg={12} className="d-flex justify-content-center ">
              <div>
                {type === "CreateCollection" && <Modal.Title>Add Collection</Modal.Title>}
                {type === "CreateCollection" &&
                  <p className="text-center mb-0">
                    Add your collection name and image
                  </p>}

                {type === "DetailCollection" && <Modal.Title>Edit Collection</Modal.Title>}
                {type === "DetailCollection" &&
                  <p className="text-center mb-0">
                    Edit your collection name and image
                  </p>}
              </div>
            </Col>
          </Row>
          <div className="modal-right">
            <button className="menu-dots" onClick={handleCloseCollection}>
              <img
                src={require("../Assets/Images/dotsweb.svg").default}
                alt="dots"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="collection-sec">
            <Row className="justify-content-center">
              <Col lg={9}>
                <Formik
                  initialValues={editData || intialData}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {

                    if (editData?._id) {
                      setIsLoading(true);
                      if (values) {
                        const ModifyData = {
                          id: values._id,
                          name: values.name,
                          image: selectedImage ? selectedImage : values.image,
                        };
                        const response = await dispatch(
                          collectionEditAction(ModifyData)
                        );
                        if (response?.payload?.statusCode == 200) {
                          toast.success(response?.payload?.message, {
                            iconTheme: {
                              primary: '#4318ff',
                              secondary: '#FFFAEE',
                            },
                          });
                          setIsLoading(false);
                          setSubmitting(false);
                          handleCloseCollection();
                          const FilterPayload = {
                            searchValue: "",
                          };
                          dispatch(collectiondetailsAction(editData?._id));
                          dispatch(collectionlistAction(FilterPayload))
                          setTimeout(() => {
                            setSlectedImage("")         
                          }, 1000);
                        } else {
                          toast.error(response?.payload?.message);
                          setIsLoading(false);
                        }
                      }
                    } else {
                      if (values) {
                        setIsLoading(true);
                        const Payload = {
                          id: values._id,
                          name: values.name,
                          image: selectedImage ? selectedImage : values.image,
                        };
                        const response = await dispatch(
                          collectioncreateAction(Payload)
                        );
                        if (response?.payload?.statusCode == 200) {
                          toast.success(response?.payload?.message, {
                            iconTheme: {
                              primary: '#4318ff',
                              secondary: '#FFFAEE',
                            },
                          });
                          setIsLoading(false);
                          setSubmitting(false);
                          handleCloseCollection();
                          const FilterPayload = {
                            searchValue: "",
                          };
                          dispatch(collectionlistAction(FilterPayload))
                          setTimeout(() => {
                            setSlectedImage("")
                          }, 1000);
                        } else {
                          toast.error(response?.payload?.message);
                          setIsLoading(false);
                        }
                      }
                    }
                  }}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                      <BootstrapForm.Group
                        className="mb-5"
                        controlId="collectionName"
                      >
                        <BootstrapForm.Label>Collection Name</BootstrapForm.Label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control input-set"
                          placeholder="Type Here"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </BootstrapForm.Group>
                      <div className="image-upload-main">
                        <Row>
                          <Col lg={7}>
                            <div className="image-upload-show">
                              {/* <BootstrapForm>
                                <BootstrapForm.Control
                                  type="file"
                                  className="collection-upld"
                                />
                              </BootstrapForm> */}
                              {values && values.image || selectedImage ? (
                                <img
                                  className="upload-prev"
                                  src={ selectedImage ? selectedImage :values.image}
                                  alt="SelectedImg"
                                />
                              ) : (
                                <div className="popup-upld-send" onClick={handleOpenColImgModal}>
                                  <img
                                    src={require("../Assets/Images/upload-pop.png")}
                                    alt="default uploader"
                                  />
                                  <h2>Choose Image </h2>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col lg={5} className="d-flex align-items-end">
                            <div className="upload-desc">
                              <h3>Choose Image </h3>
                              <p>
                                JPEG or PNG <br />
                                Accepted
                              </p>
                              <div style={{ display: "flex", justifyContent: "space-between", gap: "2px" }}>
                    
                                <Button variant="primary" className="modal-f-btn" onClick={handleOpenColImgModal}>
                                  {selectedImage || values.image ? "Update":"Choose"}
                                </Button>


                              </div>

                            </div>
                          </Col>
                          <ErrorMessage
                            name="image"
                            component="div"
                            className="text-danger"
                          />
                        </Row>
                      </div>
                      <Modal.Footer className="border-none justify-content-center mt-4">
                        <Button
                          variant="primary"
                          className="modal-f-btn"
                          type="submit"
                          // disabled={isSubmitting}
                          disabled={isLoading ? true : false}
                        >
                          {isLoading ? "loading..." : "Save"}
                        </Button>
                        {type === "DetailCollection" && 
                           <Button
                           variant="primary"
                           className="modal-f-btn"
                           type="button"
                           onClick={()=>handleDeleteOpen()}
      
                         >
                           Delete
                         </Button>
                        }
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <CollectionImagesModal Modalshow={Modalshow} handleCloseColImgModal={handleCloseColImgModal} setSlectedImage={setSlectedImage} shortContainerRef={shortContainerRef}  />
    </>
  );
};

export default CreateCollectionModal;
