
import React from 'react'
import { Modal } from 'react-bootstrap'
import ReportIssue from '../Components/ReportIssue';



const ReportModal = ({reportModalOpen,handleReportModalClose,isBrowse,reportId , shortContainerRef}) => {
  return (
    <Modal
      size="lg"
      show={reportModalOpen}
      onHide={handleReportModalClose}
      className="comn-modal-set create"
      centered
      container={shortContainerRef?.current}
    >
      <Modal.Body>
      <ReportIssue
      isBrowse={isBrowse}
      reportId={reportId}
      handleReportModalClose={handleReportModalClose}
      />
      </Modal.Body>

    </Modal>
  )
}

export default ReportModal



