import React from 'react'
import { Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import { uploadFileToS3 } from '../Util/HelperFunction';



const DropzoneArea = ({ setFieldValue, setValue, handleDeleteFileFromS3, haveImage, name }) => {
  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length == []) {
      toast.error("Image size exceeded")
    } else {
      const file = acceptedFiles[0];

      if (haveImage) {
        handleDeleteFileFromS3(haveImage, name, setFieldValue,)
        const options = {
          maxSizeMB: 0.5, // Maximum size in MB, lower value for more compression
          maxWidthOrHeight: 800, // Reduce the dimensions more significantly
          useWebWorker: true, // Use web workers for compression
          initialQuality: 0.5, // Lower the quality of the image, range is 0 to 1
      };
      const compressedFile = await imageCompression(file, options);
        const data = await uploadFileToS3(compressedFile)
        setFieldValue('image', data?.data?.imageUrl)
      } else {
        const options = {
          maxSizeMB: 0.5, // Maximum size in MB, lower value for more compression
          maxWidthOrHeight: 800, // Reduce the dimensions more significantly
          useWebWorker: true, // Use web workers for compression
          initialQuality: 0.5, // Lower the quality of the image, range is 0 to 1
      };
       const compressedFile = await imageCompression(file, options);
        const data = await uploadFileToS3(compressedFile)
        setFieldValue('image', data?.data?.imageUrl)
      }
    }
  };

  return (
    <div>
      <div className="dropzone" accept="image/jpeg, image/png">
        <Dropzone onDrop={onDrop} accept="image/*">
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="primary" className="modal-f-btn">
                {haveImage ? " Replace" : "Upload"}
              </Button>

            </div>
          )}
        </Dropzone>
      </div>
    </div>
  )
}

export default DropzoneArea
