import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { toast } from "react-hot-toast";
import { resetPassword, resetPasswordAction } from "../Redux/Action/PasswordAction";
export default function ResetPage() {

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const params = useParams()

  const validateSchema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    confirmedpassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmedpassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      if (values) {

        setIsLoading(true)
        const modifyData = {
          newPassword: values.confirmedpassword,
          id: params?.id,

        };
        const response = await dispatch(resetPassword(modifyData));
        if (response?.payload?.statusCode === 200) {
          formik.resetForm();
          toast.success("Password Reset Successfully",{
            iconTheme: {
              primary: '#4318ff',
              secondary: '#FFFAEE',
            },
          });
          navigate("/");
          setIsLoading(false)
        } else {
          toast.error(response?.payload?.message);
          setIsLoading(false)
        }
      }
    },
  });
  return (
    <div className="sign-shape">
      <Container fluid>
        <Row className="box-center">
          <Col lg={5} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="">Reset Password</h2>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail"> 
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password "
                        onChange={formik.handleChange}
                        name="password"
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.submitCount > 0 && formik.errors.password && (
                        <div className="error text-danger">
                          {formik.errors.password}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password "
                        onChange={formik.handleChange}
                        name="confirmedpassword"
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmedpassword}
                      />
                      {formik.submitCount > 0 &&
                        formik.errors.confirmedpassword && (
                          <div className="error text-danger">
                            {formik.errors.confirmedpassword}
                          </div>
                        )}
                    </Form.Group>
                  </div>

                  <button variant="primary" type="submit" className="submit" disabled={isLoading ? true :false}>
                  
                    {isLoading ? "loading..." :"Reset Password"}
                  </button>
                </Form>
              </div>
            </div>
          </Col>
          <Col lg={7} className="d-flex justify-content-center">
            <img
              style={{ opacity: "0.6" }}
              src={require("../Assets/Images/signin-logo.png")}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
