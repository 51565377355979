import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from "../../Util/HelperFunction";

const BrowseSearch = ({ searchValue, setSearchValue, hide }) => {
  const Location = useLocation()
  const navigate = useNavigate()
  const queryParams = getQueryParams(Location?.search);
  const { selectedGroup, categories, searchkey } = queryParams;
  const [searchText, setSearchText] = useState(searchValue || "");
  const browseDidYouMeanResult = useSelector((state) => state?.browseSlice?.didYouMeanResult)

    useEffect(() => {
        if (searchValue !== searchText) {
            setSearchText(searchValue || "");
        }
    }, [searchValue]);


    const navigateToBrowse = (searchKey) => {
        navigate(`/browse?searchkey=${searchKey.trim()}&categories=${categories}&selectedGroup=${selectedGroup}`);
    };

    const handleDidYouMeanClick = (val) => {
        setSearchText(val.trim())
        navigateToBrowse(val);
      };

    const handleSearchChange = (e) => {
        let value = e.target.value
        setSearchText(value);

        if (!value.trim()) {
            setSearchValue(""); 
            navigateToBrowse(value);
        }
    };

    const handleSubmit = () => {
        setSearchValue(searchText.trim());
        navigateToBrowse(searchText);

    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            setSearchValue(searchText.trim());
            navigateToBrowse(searchText);
        }
    };

    return (
        <div className={`user-pro-search user-search-input ${hide && "inner-search-bar"}`}>
            <div>
                <div className="search-container">
                    <Form.Control
                        type="search"
                        placeholder="Type anything"
                        className="form-control w-100 "
                        value={searchText}
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}

                    />
                        <button className="search-button" onClick={handleSubmit}>Search</button>

                    
                </div>
                <div>
                
                </div>
            </div>

            {browseDidYouMeanResult?.trim() && (
                <div
                    className="did-you-mean"
                    style={{ margin: "2px 0px", cursor: "pointer" }}
                    onClick={()=>handleDidYouMeanClick(browseDidYouMeanResult)}
                >
                    Did you mean: <strong>{browseDidYouMeanResult}</strong>
                </div>
            )}
        </div>
    );
};

export default BrowseSearch;



