
import React, { Suspense } from 'react';
import { FadeLoader } from "react-spinners";


const PublicRoutesSuspense = ({ children }) => (
  <div className='top-fix-sec'>
  <Suspense fallback={
  <div className="loader-style container-fluid">
  <FadeLoader
    className="fade-loader"
    loading={true}
    color={"#D61F7F"}
  />
</div>
  }>
    {children}
  </Suspense>
  </div>
);

export default PublicRoutesSuspense;