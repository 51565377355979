import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";


export const signinAction = createAsyncThunk(
    'signin', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/auth/login', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })
export const verifyOtpAction = createAsyncThunk(
    'verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export const ResetPasswordAction = createAsyncThunk(
    'resetpassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const LogotAction = createAsyncThunk(
    'resetpassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/auth/logout')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })






export const UpdatePasswordAction = createAsyncThunk(
    'UpdatePassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/auth/logout')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }


        
    })



    export const SetPasswordAction = createAsyncThunk(
        'setPassword', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('school/auth/set-password',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })


        export const UserInfoEditAction = createAsyncThunk(
            'infoEdit', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.put('school/auth/edit',reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
        
            })

            export const schoolDetailAction = createAsyncThunk(
                'info', async (reqBody, thunkAPI) => {
                    try {
                        const response = await Api.get('school/auth/info')
                        return response.data
                    } catch (error) {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
            
                })
        


            export const activityListAction = createAsyncThunk(
                'activity', async (reqBody, thunkAPI) => {
                    try {
                        const response = await Api.get('school/auth/activity', {
                            params: {
                                collectionId : reqBody.id
                            }
                        })
                        return response.data
                    } catch (error) {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
            
                })
        
            
    
        

            

   
                export const userLogTimeUpdateAction = createAsyncThunk(
                    'postTime',
                    async (reqBody, thunkAPI) => {
                      const token = localStorage.getItem('token');
                      
                      // Check if token exists
                      if (!token) {
                        // If no token is found, reject the action with an appropriate message
                        return thunkAPI.rejectWithValue({ message: 'No token found. User is not authenticated.' });
                      }
                  
                      try {
                        const response = await Api.post('school/auth/active', reqBody);
                        return response.data;
                      } catch (error) {
                        // Handle and return error response
                        return thunkAPI.rejectWithValue(error.response?.data || 'An error occurred.');
                      }
                    }
                  );