import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SocketProvider } from "./context/SocketProvider";
import { isMobile, isTablet } from "react-device-detect";

// URL Redirection Logic (Runs before React is mounted)
const handleRedirection = () => {
  if (typeof window !== "undefined") {
    const currentUrl = window.location.href;
    const pathSegments = new URL(currentUrl).pathname.split("/").filter(Boolean);
    const id = pathSegments[pathSegments.length - 1];

    const urlParams = new URLSearchParams(window.location.search);
    const isShareable = urlParams.get("isShareable") === "true";

    console.log("urlContainsVideoShorts", typeof isShareable, isShareable);

    if ((isMobile || isTablet) && isShareable) {
      window.location.href = `https://peerscroller.onelink.me/gTJq/fydlv6xg?videoId=${id}&af_force_deeplink=true`;
    }
  }
};

// Run redirection before rendering React app
handleRedirection();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SocketProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SocketProvider>
  </React.StrictMode>
);

// Web Vitals Reporting
reportWebVitals();
