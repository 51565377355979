import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import { avatarAction } from "../Redux/Action/CollectionAction";

const CollectionImagesModal = ({ Modalshow, handleCloseColImgModal, setSlectedImage , shortContainerRef }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(false);



  useEffect(() => {
    getStudentImage();
  }, []);

  const getStudentImage = async() => {
    setLoading(true);
   const res =  await dispatch(avatarAction())
    if(res?.payload?.statusCode === 200){
        setImages(res?.payload?.data)  
        setLoading(false)
    }else{
        setImages([])
        setLoading(false)
    }

  };

  return (
    <Modal
      size="lg"
      show={Modalshow}
      onHide={handleCloseColImgModal}
      className="comn-modal-set"
      container={shortContainerRef?.current}
    >
      <Modal.Header closeButton className="border-none">
        <Modal.Title className="text-center">Choose Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="collections-list-pro">
          <Row>
            {loading ? (
              <div className="d-flex justify-content-center w-100">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : Array.isArray(images) && images.length > 0 ? (
              <>
                {images.map((num, index) => (
                  <React.Fragment key={index}>
                    {num.image ? (
                      <Col lg={2}>
                        <div
                          onClick={() => {
                            setSlectedImage(num.image)
                            handleCloseColImgModal()
                          }}
                          className="search-history-list collect-box"
                        >
                          <div className="viewing-h-pro">
                            <img src={num.image} alt="avatar" />
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <p className="data-not-found-cmn">Data Not Found</p>
            )}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-none justify-content-center">
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionImagesModal;









