export const categories = [
    "Culture and Free Time",
    "Digital Wellbeing",
    "Make Your Voice Heard",
    "Who are you",
    "Life Skills",
    "Money Matters",
    "Exam Revision",
    "Careers & Options",
    "Relationships"
];


export const careerOptions = [
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    },
    {
        title: "The Romans & Celts in Britain",
        year: "Careers & Options . Yr 9",
        category: "Culture and Free Time"
    }
];




export const  groupData= [
    // {"id": 1, "name": "One"},
    // {"id": 2, "name": "Two"},
    {"id": 1, "name": "KS2 Lower"},
    {"id": 2, "name": "KS2 Higher"},
    {"id": 3, "name": "KS3"},
    {"id": 4, "name": "KS4"},
    {"id": 14, "name": "College"},
    // {"id": 5, "name": "KS5"},
    // {"id": 6, "name": "Six"},
    // {"id": 7, "name": "Seven"},
    // {"id": 8, "name": "Eight"},
    // {"id": 9, "name": "Nine"},
    // {"id": 10, "name": "Ten"},
    // {"id": 11, "name": "Eleven"},
    // {"id": 12, "name": "Twelve"}
]



export const  YearData= [
    {"value": 3, "name": "Three"},
    {"value": 4, "name": "Four"},
    {"value": 5, "name": "Five"},
    {"value": 6, "name": "Six"},

    {"value": 7, "name": "Seven"},
    {"value": 8, "name": "Eight"},
    {"value": 9, "name": "Nine"},
    {"value": 10, "name": "Ten"},
    {"value": 11, "name": "Eleven"},
    {"value": 14, "name": "College"},
]







export const NodataChart = [
    { day: "Monday", hours: 0},
    { day: "Tuesday", hours: 0 },
    { day: "Wednesday", hours: 0 },
    { day: "Thursday", hours: 0 },
    { day: "Friday", hours: 0 },
    { day: "Saturday", hours: 0},
    { day: "Sunday", hours: 0},
  ];


export const issueTypeData = [
    { type: "Login problems" },
    { type: "Account settings errors" },
    { type: "Video playback errors" },
    { type: "Sensitive video" },
    { type: "App crashes or freezes" },
    { type: "Connectivity problems" },
    { type: "General feedback" },
    { type: "Suggestions for improvement" },
    { type: "Other" }
  ];