import React from 'react';
import { Col } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Encryptedid } from '../../Util/BcruptEncyptid';

const ShareVideo = ({ data, shortContainerRef, fullscreenState }) => {
  const encryptedId = data?.categoryId?._id ? Encryptedid(data.categoryId._id) : '';
  const encryptedVideoId = data?._id ? Encryptedid(data?._id) : '';

  const shareUrl = encryptedId && data?._id
    ? `https://use.peerscroller.com/VideoShorts/${encryptedId}/${data?._id}?isShareable=true`
    : '';

  const handleCopyUrl = () => {
    if (shareUrl) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareUrl)
          .then(() => {
            toast.success('URL copied to clipboard!', {
              container: shortContainerRef?.current, // Attach toast to fullscreen container
            });
          })
          .catch(() => {
            toast.error('Failed to copy URL.', {
              container: shortContainerRef?.current, // Attach toast to fullscreen container
            });
          });
      } else {
        // Fallback method
        const textarea = document.createElement('textarea');
        textarea.value = shareUrl;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          toast.success('URL copied to clipboard!', {
            container: shortContainerRef?.current, // Attach toast to fullscreen container
          });
        } catch (err) {
          toast.error('Failed to copy URL.', {
            container: shortContainerRef?.current, // Attach toast to fullscreen container
          });
        }
        document.body.removeChild(textarea);
      }
    } else {
      toast.error('Unable to generate share URL.', {
        container: shortContainerRef?.current, // Attach toast to fullscreen container
      });
    }
  };

  return (
    <Col className="btns-main" ref={shortContainerRef?.current}>
      <button className="download-cover view-reels-side-btn" onClick={handleCopyUrl}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26" fill="none">
          <path d="M13.8473 17.0668V21.2846V23.1789C13.8473 23.8086 13.3349 24.3228 12.6996 24.3228H1.89028C1.25894 24.3228 0.75 23.8123 0.75 23.1789V7.30021C0.75 6.66635 1.25881 6.15601 1.89028 6.15601H3.78795H11.2L11.9496 8.68625C11.9496 8.69398 11.9495 8.70177 11.9495 8.70961C11.949 9.71316 11.948 11.6454 12.1771 13.3624C12.2959 14.253 12.4839 15.1487 12.7992 15.8414C13.009 16.3025 13.3412 16.8062 13.8473 17.0668Z" stroke="#6475E0" stroke-width="1.5" />
          <path d="M19.7337 6.23081L19.7336 6.23084L19.7362 6.23331C19.7731 6.26839 19.8021 6.31078 19.8216 6.35775L19.9602 6.30026L19.8216 6.35775C19.8411 6.40472 19.8506 6.45524 19.8494 6.50608L19.8494 6.50608V6.50952V17.7729C19.8494 18.735 19.0677 19.5171 18.1017 19.5171H7.2961C6.32992 19.5171 5.54844 18.735 5.54844 17.7729V1.89396C5.54844 0.932097 6.3299 0.150022 7.2961 0.150022L13.4898 0.150029L13.4912 0.150016C13.5418 0.149546 13.5921 0.159403 13.6388 0.178983C13.6855 0.198564 13.7278 0.227459 13.763 0.263905L13.763 0.263921L13.7649 0.265798L19.7337 6.23081Z" fill="#6475E0" stroke="#6475E0" stroke-width="0.3" />
        </svg>
      </button>
      <p>Copy Link</p>
      {/* Render the Toaster within the fullscreen container */}
      {shortContainerRef?.current && fullscreenState && <Toaster container={shortContainerRef?.current} />}
    </Col>
  );
};

export default ShareVideo;