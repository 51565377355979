import React from 'react'

const SaveButton = ({ handleShow, isSaved }) => {
  return (
    <div className="btns-main">
      <button onClick={() => handleShow(isSaved?._id)} className='view-reels-side-btn'>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
          <path d="M14.5431 1.02804C14.7191 0.63213 15.2809 0.63213 15.4569 1.02804L19.0218 9.04897C19.0937 9.2109 19.246 9.32265 19.4221 9.34269L28.8541 10.4162C29.2889 10.4657 29.4549 11.0103 29.1215 11.2938L21.9605 17.3851C21.8157 17.5083 21.7533 17.7029 21.7994 17.8873L24.018 26.7615C24.1259 27.1934 23.658 27.5405 23.276 27.3118L15.2568 22.5105C15.0987 22.4158 14.9013 22.4158 14.7432 22.5105L6.72395 27.3118C6.34196 27.5405 5.87405 27.1934 5.98203 26.7615L8.2006 17.8873C8.2467 17.7029 8.18426 17.5083 8.03949 17.3851L0.878453 11.2938C0.545136 11.0103 0.711088 10.4657 1.14587 10.4162L10.5779 9.34269C10.7539 9.32265 10.9063 9.21091 10.9782 9.04897L14.5431 1.02804Z" fill="#FFC851" />
        </svg>
      </button>
      <p>Save</p>
    </div>
  )
}

export default SaveButton
