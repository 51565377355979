
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormBootstrap from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { ReportIssueAction, ReportVideoAction } from "../Redux/Action/ReportAction";
import { issueTypeData } from "../Constant/ConstantData";

const ReportIssue = ({isBrowse,reportId ,handleReportModalClose}) => {

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);


    const initialValues = {
        issueType: "",
        description: "",
    };

    const validationSchema = Yup.object({
        issueType: Yup.string().required("Issue Type is required"),
        description: Yup.string().required("Description is required"),
    });

    const handleSubmit =async (values, { setSubmitting,resetForm }) => {
        if(values){
            setIsLoading(true)
               const data = {
                issueType:values.issueType,
                comment:values.description,
                id: isBrowse ? reportId : ""
               }

                const response = await dispatch(ReportIssueAction(data))
                if (response?.payload?.statusCode == 200) {
                  toast.success(response?.payload?.message,{
                    iconTheme: {
                      primary: '#4318ff',
                      secondary: '#FFFAEE',
                    },
                  })
                  setIsLoading(false)
                  resetForm();
                  handleReportModalClose()
                } else {
                  toast.error(response?.payload?.message)
                  setIsLoading(false)
                }

                setSubmitting(false);
    
        }
    
    
    };

    return (
        <div className="student-view">
            <h2>Report an Issue</h2>
            <p>Found a problem with Peerscroller?</p>
            <p>Let us know via the form below, and a member of our team will be in touch to resolve it within the next seven days.</p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="fullScreanReport">
                        <FormBootstrap.Group className="mb-3" controlId="formBasicEmail">
                            <FormBootstrap.Label>Issue Type</FormBootstrap.Label>
                            <Field
                                as="select"
                                name="issueType"
                                className="form-control"
                            >
                                <option value="">Select Issue Type</option>
                                {issueTypeData.map((ele,index)=>{
                                    return(
                                        <>
                                         <option value={ele.type} key={index}>{ele.type}</option>
                                        </>
                                    )
                                })}
                            </Field>
                            <ErrorMessage
                                name="issueType"
                                component="div"
                                className="text-danger"
                            />
                        </FormBootstrap.Group>

                        <FormBootstrap.Group className="mb-3" controlId="formBasicPassword">
                            <FormBootstrap.Label>Description</FormBootstrap.Label>
                            <Field
                                as="textarea"
                                name="description"
                                rows={3}
                                className="form-control"
                                placeholder="Type here"
                            />
                            <ErrorMessage
                                name="description"
                                component="div"
                                className="text-danger"
                            />
                        </FormBootstrap.Group>

                        <Button type="submit" className="cmn-pink w-50 mb-3 mt-4 modal-f-btn" disabled={isLoading ? true :false}>
                           

                      {isLoading ? "loading..." :"Send"}
                        </Button>
                        
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ReportIssue;
