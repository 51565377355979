import React, { useState } from 'react';
import { likesAction } from '../../Redux/Action/LikesAction';
import { useDispatch } from 'react-redux';

const LikeButton = ({ initialLike }) => {
    const dispatch = useDispatch();
    const [liked, setLiked] = useState(initialLike.status);

    const handleToggleLike = () => {
        const newLikedStatus = !liked;
        setLiked(newLikedStatus);
        const payload = {
            id: initialLike._id,
            status: newLikedStatus.toString()
        };
        dispatch(likesAction(payload));
    };

    return (
        <>
            {liked ?
                <div className="btns-main">
                    <button onClick={handleToggleLike} className='view-reels-side-btn'>
                        <svg
                            width="26"
                            height="23"
                            viewBox="0 0 26 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.22101 12.7497L11.973 20.9712L11.992 20.9891C12.1376 21.126 12.2801 21.2599 12.413 21.3555C12.5633 21.4637 12.7553 21.564 13 21.564C13.2447 21.564 13.4367 21.4637 13.587 21.3555C13.7199 21.2599 13.8624 21.126 14.008 20.9891L14.027 20.9712L22.779 12.7497C25.4122 10.2761 25.7319 6.20552 23.5173 3.35109L23.1306 2.85268C20.4423 -0.612286 15.0461 -0.031184 13.1571 3.9267C13.094 4.05882 12.906 4.05882 12.8429 3.9267C10.9539 -0.0311847 5.55772 -0.612281 2.86939 2.85268L2.48269 3.35109C0.268048 6.20552 0.587817 10.2761 3.22101 12.7497Z"
                                fill="#FF0000"
                                stroke="#EEE7EA"
                            />
                        </svg>
                    </button>
                    <p>Like</p>
                </div>


                :
                <div className="btns-main">
                    <button onClick={() => handleToggleLike()} className='view-reels-side-btn'>
                        <svg
                            width="26"
                            height="23"
                            viewBox="0 0 26 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.22101 12.7497L11.973 20.9712L11.992 20.9891C12.1376 21.126 12.2801 21.2599 12.413 21.3555C12.5633 21.4637 12.7553 21.564 13 21.564C13.2447 21.564 13.4367 21.4637 13.587 21.3555C13.7199 21.2599 13.8624 21.126 14.008 20.9891L14.027 20.9712L22.779 12.7497C25.4122 10.2761 25.7319 6.20552 23.5173 3.35109L23.1306 2.85268C20.4423 -0.612286 15.0461 -0.031184 13.1571 3.9267C13.094 4.05882 12.906 4.05882 12.8429 3.9267C10.9539 -0.0311847 5.55772 -0.612281 2.86939 2.85268L2.48269 3.35109C0.268048 6.20552 0.587817 10.2761 3.22101 12.7497Z"
                                fill="#EEE7EA"
                                stroke="#EEE7EA"
                            />
                        </svg>
                    </button>
                    <p>Like</p>
                </div>




            }





        </>
    );
};

export default LikeButton;
