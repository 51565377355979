import React from 'react';
const DownloadCover = ({ data, shortContainerRef }) => {
    return (
        <div className="btns-main" ref={shortContainerRef?.current}>
            <button className="download-cover view-reels-side-btn">
                <abbr title="Download Cover">
                    <a
                        href={data?.cover_url}
                        target="_blank"
                        download
                        rel="noopener noreferrer"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M27.5793 18.8057C27.2991 18.5253 26.9583 18.385 26.5579 18.385H19.5865L17.5432 20.4282C16.9623 20.989 16.2812 21.2695 15.5 21.2695C14.7189 21.2695 14.0377 20.9894 13.4568 20.4282L11.4285 18.385H4.44229C4.04172 18.385 3.7012 18.5255 3.42067 18.8057C3.14019 19.0861 3 19.4266 3 19.8275V24.635C3 25.0357 3.14019 25.3762 3.42067 25.6566C3.70115 25.937 4.04167 26.0771 4.44229 26.0771H26.5579C26.9584 26.0771 27.2992 25.937 27.5793 25.6566C27.8599 25.3762 28 25.0357 28 24.635V19.8275C28 19.4266 27.8599 19.0861 27.5793 18.8057ZM21.9456 23.8687C21.755 24.0591 21.5296 24.1542 21.2692 24.1542C21.0087 24.1542 20.7836 24.0591 20.5931 23.8687C20.4029 23.6784 20.3078 23.4531 20.3078 23.1929C20.3078 22.9325 20.4029 22.7068 20.5931 22.5168C20.7836 22.3263 21.0087 22.231 21.2692 22.231C21.5296 22.231 21.755 22.3263 21.9456 22.5168C22.1357 22.7068 22.2308 22.9324 22.2308 23.1929C22.2308 23.4531 22.1357 23.6784 21.9456 23.8687ZM25.7915 23.8687C25.6013 24.0591 25.3759 24.1542 25.1154 24.1542C24.855 24.1542 24.6297 24.0591 24.4393 23.8687C24.2492 23.6784 24.1541 23.4531 24.1541 23.1929C24.1541 22.9325 24.2492 22.7068 24.4393 22.5168C24.6297 22.3263 24.8549 22.231 25.1154 22.231C25.3758 22.231 25.6012 22.3263 25.7915 22.5168C25.9818 22.7068 26.0771 22.9324 26.0771 23.1929C26.0771 23.4531 25.982 23.6784 25.7915 23.8687Z" fill="#B27BFA" />
                            <path d="M14.8247 19.061C15.0051 19.2511 15.2304 19.3463 15.5008 19.3463C15.7713 19.3463 15.9964 19.2511 16.1768 19.061L22.9076 12.3301C23.2181 12.0397 23.2883 11.689 23.118 11.2784C22.948 10.8879 22.6524 10.6926 22.2316 10.6924H18.3853V3.96158C18.3853 3.7012 18.29 3.47582 18.1001 3.28553C17.9096 3.09519 17.6843 3 17.424 3H13.5775C13.3172 3 13.0918 3.09519 12.9014 3.28553C12.7112 3.47582 12.616 3.70115 12.616 3.96158V10.6924H8.76986C8.34898 10.6924 8.05366 10.8879 7.88338 11.2784C7.71319 11.6889 7.78324 12.0397 8.09382 12.3302L14.8247 19.061Z" fill="#B27BFA" />
                        </svg>
                    </a>
                </abbr>
            </button>
            <p>Download Thumbnail</p>
        </div>
    );
};

export default DownloadCover;
